<template>
  <v-container>
    <v-overlay :value="loading || status.updating">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <v-card outlined>
        <v-card-title :class="$classes.cardTitle">
                <span class="font-weight-bold title mr-5 mb-3 d-flex align-center">Upload Excel</span>
        </v-card-title>
        <v-card-text class="pa-10">
            <v-file-input
                label="File input"
                outlined
                dense
                v-model="file"
                :value="file ? file : []"
            >
            </v-file-input>
            <v-btn :disabled="!file" class="mb-5" @click="convertToJson">Convert</v-btn>

            <div
                v-show="data.length && hasConverted && items.length"
            >
                <div
                >
                    <v-autocomplete
                        v-model="value"
                        :items="items"
                        dense
                        filled
                        label="Food name"
                    >
                    </v-autocomplete>
                </div>
                <v-card v-if="selectedItem.name" outlined class="pa-3 mb-3">
                    <v-card-text>
                        <h3 class="primary--text font-weight-bold">{{ selectedItem.name }}</h3>
                    </v-card-text>
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <h5 class="primary--text font-weight-bold">Nutrition Tags</h5>
                            </v-col>
                        </v-row>
                        <v-row no-gutters class="mb-3">
                            <v-col 
                            v-for="(tag, i) in nutritionTags" 
                            :key="i"
                            cols="12"
                            md="6"
                            lg="3"
                            xl="3"
                            >
                                <v-icon color="primary" small v-if="selectedItem[tag] === 'Yes'">mdi-check</v-icon>
                                <v-icon color="error" small v-else>mdi-minus-circle-outline</v-icon>
                                {{ tag }}
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col>
                                <h5 class="primary--text font-weight-bold">Meal Tags</h5>
                            </v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col 
                            v-for="(tag, i) in mealTags" 
                            :key="i"
                            cols="12"
                            md="6"
                            lg="3"
                            xl="3"
                            >
                                <v-icon color="primary" small v-if="selectedItem[tag] === 'Yes'">mdi-check</v-icon>
                                <v-icon color="error" small v-else>mdi-minus-circle-outline</v-icon>
                                {{ tag }}
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <h5 class="primary--text font-weight-bold">Others</h5>
                            </v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col 
                            v-for="(other, i) in others" 
                            :key="i"
                            cols="12"
                            md="6"
                            lg="3"
                            xl="3"
                            >
                                <span class="primary--text" small v-if="selectedItem[other]">{{ selectedItem[other] }}</span>
                                <v-icon color="error" small v-else>mdi-minus-circle-outline</v-icon>
                                {{ other }}
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>

                <div class="d-flex justify-start gap-10">
                    <v-btn :disabled="!selectedItem.name" class="mb-5 primary" @click="updateOne">Update one</v-btn>
                    <v-btn class="mb-5 info" @click="updateAll">Update All</v-btn>
                </div>
            </div>
        </v-card-text>
    </v-card>
  </v-container>  
</template>

<script>
import * as XLSX from "xlsx"
import { mapActions, mapState } from "vuex"

export default {
    name: "UploadExcel",
    data () {
        return {
            file: "",
            data: [],
            loading: false,
            hasConverted: false,
            items: [],
            value: null,
            selectedItem: {},
        }
    },
    computed: {
        ...mapState({
            status: state => state.excel.status,
            nutritionTags: state => state.excel.nutritionTags,
            mealTags: state => state.excel.mealTags,
            others: state => state.excel.others
        })
    },
    watch: {
        value: function(newVal, oldVal) {
            if(newVal !== oldVal) {
                const selected = this.data.find(item =>item.name === newVal)

                this.selectedItem = {}
                this.selectedItem = selected
            }
        }
    },
    methods: {
        ...mapActions('excel', ['updateOneFood', 'updateAllFood']),
        async formatData () {
            return new Promise((res) => {
                this.loading = true;
                let dataItems;
                let fileReader = new FileReader()
                fileReader.readAsBinaryString(this.file);
                fileReader.onload = (event) => {
                    let data = event.target.result;
                    let workbook = XLSX.read(data, { type: "binary"});

                    workbook.SheetNames.forEach(sheet => {
                        let rowObject = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheet])
                        dataItems = rowObject
                    })

                    const items = dataItems.map(d => d.name)

                    this.data = dataItems
                    this.items = items
                    this.loading = false;
                    this.$store.dispatch('showSuccess', 'Successfully converted!')
                    this.hasConverted = true
                    res()
                }
            })
        },
        async convertToJson () {
            if(this.file) {
                await this.formatData()
            }
        },
        updateOne () {
            this.updateOneFood(this.selectedItem)
        },
        updateAll () {
            this.updateAllFood(this.data)
        }
    }
}
</script>

<style>

</style>